<template>
  <div class="table-bg p-3" style="width: 100%">
    <div class="table-light-bg" style="">
      <table class v-if="fnbList.length > 0">
        <thead>
          <tr>
            <td>Item</td>
            <td>Quantity</td>
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(fnbitem, fnbitemKey) in fnbList" :key="fnbitemKey">
            <td class="align-text-center pr-4">{{ fnbitem.item_name }}</td>
            <td class="align-text-center pr-4">{{ fnbitem.quantity }}</td>
            <td class="align-text-center pr-5">
              {{ currFormat(fnbitem.fp_total_amount * fnbitem.quantity) }}
              {{ currCode }}
            </td>
          </tr>
          <tr>
            <td>
              <h6 class="mb-0 font-weight-bold">Sub Total :</h6>
            </td>
            <td></td>
            <td>
              <h6 class="mb-0 font-weight-bold">
                {{ subTotal }}
                {{ currCode }}
              </h6>
            </td>
          </tr>

          <tr>
            <td>
              <h6 class="mb-0 font-weight-bold">{{ $t("tax") }}</h6>
            </td>
            <td></td>
            <td>
              <h6 class="mb-0 font-weight-bold">
                {{ currFormat(grossTotal - subTotal) }}
                {{ currCode }}
              </h6>
            </td>
          </tr>

          <tr>
            <td class="total_amt_border">
              <h5 class="mb-0 font-weight-bold">Gross Total :</h5>
            </td>
            <td></td>
            <td class="total_amt_border">
              <h5 class="mb-0 font-weight-bold">
                {{ grossTotal }}
                {{ currCode }}
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fnbList: {
      type: Array,
    },
    fnbtotalamount: {
      type: Number,
    },
    currCode: {
      type: String,
    },
    charges: {
      type: Array,
    },
  },
  computed: {
    subTotal() {
      const fnbList = this.fnbList;

      return fnbList && fnbList.length > 0
        ? this.currFormat(
            fnbList.reduce((ac, x) => (ac += x.fp_amount * x.quantity), 0)
          )
        : "-";
    },
    grossTotal() {
      const fnbList = this.fnbList;

      return fnbList && fnbList.length > 0
        ? this.currFormat(
            fnbList.reduce((ac, x) => (ac += x.fp_total_amount * x.quantity), 0)
          )
        : "-";
    },
  },
};
</script>
<style scoped>
table {
  width: 100%;
}

table,
th,
td {
  border-bottom: 1px solid #000;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}

.table-bg {
  background-color: #fff;
  height: 100%;
  transform: scale(0.7);
  transform: top;
  margin-left: -150px;
}

.table-light-bg {
  box-shadow: rgb(128 128 128 / 68%) 1px 1px 9px 1px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 20px;
  border-radius: 20px;
}
</style>
