<template>
  <!-- v-if="section.seatLayout" -->
  <div
    :class="fullscreen && posFnb ? '' : 'pos-seatlayout-wrapper-fullscreen'"
    class="width:100%"
  >
    <div v-if="fullscreen && posBooking" style="margin: auto; width: 100%">
      <header class="pos-seatlayout-header alignment">
        <!--zoom:0.7-->
        <div class="row justify-content-center d-flex" style="margin: auto">
          <div class="d-flex align-items-center px-3">
            <div class="d-flex">
              <h6 class="mb-0">{{ getScreenPosNames }}</h6>
              <!-- <div class="mx-2">|</div>
              <h6 class="mb-0 font-weight-bold">A1</h6>-->
            </div>
          </div>
          <div class>
            <div class="d-flex justify-content-end align-items-center">
              <div class="mx-1">
                <div class="pos-seat my-0 love-seat mx-auto" />
                <small>{{ $t("love") }} {{ $tc("seat", 1) }}</small>
              </div>
              <div class="mx-1">
                <div class="pos-seat my-0 vip-seat seat-fixed-width mx-auto" />
                <small>VIP {{ $tc("seat", 1) }}</small>
              </div>
              <div class="mx-1">
                <div
                  class="pos-seat my-0 recliner-seat seat-fixed-width mx-auto"
                />
                <small>{{ $t("recliner") }} {{ $tc("seat", 1) }}</small>
              </div>
              <div class="mx-1">
                <div
                  class="pos-seat my-0 regular-seat seat-fixed-width mx-auto"
                />
                <small>Regular {{ $tc("seat", 1) }}</small>
              </div>
              <div class="mx-1">
                <div class="pos-seat my-0 sofa-seat seat-fixed-width mx-auto" />
                <small>Sofa {{ $tc("seat", 1) }}</small>
              </div>
              <div class="mx-1" style="position: relative; top: 2px">
                <div class="text-center mb-1">
                  <img
                    src="@/assets/img/pos/wheelchair.svg"
                    height="auto"
                    width="22"
                    alt
                  />
                </div>
                <small>{{ $t("wheelchair") }}</small>
              </div>

              <div class="divider mx-1 mx-xl-3" />

              <div class="mx-1">
                <div class="pos-seat my-0 taken seat-fixed-width mx-auto" />
                <small>{{ $t("taken") }}</small>
              </div>

              <div class="mx-1">
                <div
                  class="pos-seat my-0 your-selection seat-fixed-width mx-auto"
                />
                <small>{{ $t("your") }} {{ $t("selection") }}</small>
              </div>

              <div class="divider mx-1 mx-xl-3" />

              <div class="mx-1 mx-xl-3 text-center pr-2">
                <button
                  class="btn-transparent mx-auto"
                  @click="showFullScreenFunc()"
                >
                  <img
                    src="@/assets/img/icons/fullscreen-icon.svg"
                    class="d-block mx-auto"
                    height="20"
                    width="20"
                  />
                  <small v-if="!fullscreen"
                    >{{ $t("full") }} {{ $tc("screen", 2) }}</small
                  >
                  <small v-if="fullscreen"
                    >{{ $t("exit") }} {{ $t("full") }}
                    {{ $tc("screen", 2) }}</small
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!---ZOOM:0.7-->
      <div class="relative text-center" style="width: 50%; margin: auto">
        <img
          src="@/assets/img/pos/pos-screen.png"
          class="img-fluid w-100 alignment"
        />
        <span
          class="eyes-text mb-2"
          :class="
            reverse
              ? 'pos-seatlayout-rotate'
              : QfxClient
              ? 'font-weight-bold'
              : ''
          "
          :style="`font-size:${QfxClient ? '22px' : ''}`"
          >{{ QfxClient ? $t("SCREEN") : $t("screen_title") }}</span
        >
      </div>
      <div
        class="table-responsive pos-seatlayout mt-3"
        :class="reverse ? 'pos-seatlayout-rotate' : ''"
      >
        <div class="alignment">
          <table
            class=""
            v-for="(seat, seatIndex) in seatType"
            :key="seatIndex"
          >
            <!-- <tr>
            <td colspan="30" class="pos-seatLayoutTitle">
              
            </td>
          </tr> -->
            <!-- REGULAR SEATS -->

            <!-- SLIVER CLASS LABEL -->
            <!-- <tr>
            <td colspan="22">
              <div class="pos-seat-class">
                {{ seat.seatType }}
                <strong>({{ seat.seat_price }} {{ curr_code }})</strong>
              </div>
            </td>
          </tr> -->

            <tr v-if="!isWheelChairOnlyRow(seat.seatsFinal)">
              <td colspan="30">
                <!-- seatTypetext -->
                <div class="relative text-uppercase">
                  <span class="seatTypetext">
                    {{ seat.seatType }}
                  </span>
                </div>
              </td>
            </tr>

            <tr
              v-for="(seatRow, seatRowIndex) in seat.seatsFinal"
              :key="seatRowIndex"
            >
              <!-- <td> -->
              <!-- <div class="text-center lh-1">{{seatRowIndex}}</div> -->
              <!-- </td> -->
              <!-- <td>
            <div class="pos-seatNumber">1</div>
            <div v-on:click="onClickSeatFunc()" class="pos-seat regular-seat your-selection"></div>
            </td>-->
              <!-- <td>
            <div class="pos-seatNumber">2</div>
            <div v-on:click="onClickSeatFunc()" class="pos-seat regular-seat your-selection"></div>
            </td>-->
              <td
                v-for="(singleSeat, singleSeatIndex) in seatRow"
                :key="'index' + singleSeatIndex"
                :class="reverse ? 'pos-seatlayout-rotate' : ''"
              >
                <!-- {{singleSeat.seat_reserve_type_id}} -->
                <div v-if="singleSeat.srt_id == 2" class="pos-seatNumber" />
                <!-- <div v-else class="pos-seatNumber">
                  {{ singleSeat.sl_seat_name }}
                </div> -->
                <div
                  :ref="`${singleSeat.sl_id}`"
                  :id="singleSeat.sl_id"
                  v-if="
                    singleSeat.covidBlocked && singleSeat.covidBlocked === true
                  "
                  class="text-center"
                >
                  <CovidBlockedSeatIcon
                    :active="singleSeat.selectStatus == true"
                  />
                </div>
                <div
                  :ref="`${singleSeat.sl_id}`"
                  :id="singleSeat.sl_id"
                  v-else-if="singleSeat.seat_reserve_type_id == 5"
                  class="pos-seat disabled"
                  :class="
                    singleSeat.seat_reserve_type_id == 5
                      ? 'vip-seat'
                      : 'normal-seat'
                  "
                />
                <div
                  v-else-if="
                    singleSeat.srt_id == 2 ||
                    singleSeat.srt_id == 12 ||
                    singleSeat.srt_id == 13
                  "
                />
                <div
                  :ref="`${singleSeat.sl_id}`"
                  :id="singleSeat.sl_id"
                  v-else-if="singleSeat.srt_id == 4"
                  class="pos-seat cursor-pointer"
                  :style="{
                    backgroundColor: 'lightgrey',
                    border: 'none',
                  }"
                >
                  <img
                    :src="_GetImagePath(singleSeat.srt_dash_image_url_disabled)"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>
                <div
                  :ref="`${singleSeat.sl_id}`"
                  :id="singleSeat.sl_id"
                  v-else
                  class="pos-seat cursor-pointer"
                  :style="{
                    backgroundColor: singleSeat.sst_seat_color_code,
                    borderColor: singleSeat.sst_seat_color_code,
                  }"
                >
                  <img
                    v-if="singleSeat.selectStatus == true"
                    :src="
                      singleSeat.selectStatus == true
                        ? _GetImagePath(singleSeat.srt_pos_image_url_selected)
                        : _GetImagePath(singleSeat.srt_pos_image_url)
                    "
                    width="100%"
                    height="100%"
                    alt="1one"
                  />
                  <img
                    v-else-if="singleSeat.seat_reserve_type_id == 8"
                    :src="_GetImagePath(singleSeat.srt_dash_image_url_disabled)"
                    width="100%"
                    height="100%"
                    alt="2two"
                  />
                  <img
                    v-else
                    :src="_GetImagePath(singleSeat.srt_pos_image_url)"
                    width="100%"
                    height="100%"
                    alt="3three"
                  />
                </div>
                <div class="pos-seatNumber">
                  {{ singleSeat.sl_seat_name ? singleSeat.sl_seat_name : "" }}
                </div>
              </td>
              <!-- <td>
            <div class="text-center lh-1">{{seatRowIndex}}</div>
            </td>-->
            </tr>

            <!-- <tr v-for="(loop , regularSeatsRow) in 3" :key="regularSeatsRow">
          <td>
            <div v-if="regularSeatsRow == 0" class="text-center lh-1">A</div>
            <div v-if="regularSeatsRow == 1" class="text-center lh-1">B</div>
            <div v-if="regularSeatsRow == 2" class="text-center lh-1">C</div>
          </td>
          <td v-for="(loop , regularSeats) in 20" :key="regularSeats">
            <div class="pos-seatNumber">{{regularSeats}}</div>
            <div v-on:click="onClickSeat()" class="pos-seat regular-seat"></div>
          </td>
          <td>
            <div v-if="regularSeatsRow == 0" class="text-center lh-1">A</div>
            <div v-if="regularSeatsRow == 1" class="text-center lh-1">B</div>
            <div v-if="regularSeatsRow == 2" class="text-center lh-1">C</div>
          </td>
          </tr>-->

            <!-- GOLD CLASS LABEL-->
            <!-- <tr>
          <td colspan="22">
            <div class="pos-seat-class">
              Gold Class
              <strong>(300 AED)</strong>
            </div>
          </td>
          </tr>-->

            <!-- <tr v-for="(loop , vipSeatsRow) in 3" :key="vipSeatsRow">
          <td>
            <div v-if="vipSeatsRow == 0" class="text-center lh-1">D</div>
            <div v-if="vipSeatsRow == 1" class="text-center lh-1">E</div>
            <div v-if="vipSeatsRow == 2" class="text-center lh-1">F</div>
          </td>
          <td>
            <div class="pos-seatNumber">0</div>
            <div class="pos-seat wheelchair"></div>
          </td>
          <td v-for="(loop , vipSeatKey) in 19" :key="vipSeatKey">
            <div class="pos-seatNumber">{{vipSeatKey}}</div>
            <div class="pos-seat vip-seat"></div>
          </td>
          <td>
            <div v-if="vipSeatsRow == 0" class="text-center lh-1">D</div>
            <div v-if="vipSeatsRow == 1" class="text-center lh-1">E</div>
            <div v-if="vipSeatsRow == 2" class="text-center lh-1">F</div>
          </td>
          </tr>-->

            <!-- RECLINER CLASS LABEL-->
            <!-- <tr>
          <td colspan="22">
            <div class="pos-seat-class">
              Recliner Class
              <strong>(500 AED)</strong>
            </div>
          </td>
          </tr>-->

            <!-- <tr v-for="(loop , recliner) in 2" :key="recliner">
          <td>
            <div v-if="recliner == 0" class="text-center lh-1">G</div>
            <div v-if="recliner == 1" class="text-center lh-1">H</div>
          </td>
          <td colspan="2" v-for="(loop , loveSeatKey) in 10" :key="loveSeatKey">
            <div class="pos-seatNumber">{{loveSeatKey}}</div>
            <div class="pos-seat love-seat"></div>
          </td>
          <td>
            <div v-if="recliner == 0" class="text-center lh-1">G</div>
            <div v-if="recliner == 1" class="text-center lh-1">H</div>
          </td>
          </tr>-->
          </table>
        </div>
      </div>
    </div>
    <FnBitems
      v-else-if="fullscreen && posFnb"
      :fnbList="FnBList"
      :currCode="curr_code"
      :charges="AdditionalChargeList"
    />
    <!-- :addtionalcharge="additonalChargeList" -->
    <FinalTicket
      v-else-if="showCheckout"
      :BookingDetails="BookingDetails"
      :FnbItems="FnBList"
      :curr_code="curr_code"
      :charges="AdditionalChargeList"
    />
    <div
      class="h-100 d-flex justify-content-center align-items-center"
      v-else-if="orgDetails && Object.keys(orgDetails).length > 0"
    >
      <!-- <img :src="URL_BASE + orgDetails.org_logo_url" alt /> -->
      <img :src="orgDetails.org_logo_url" width="135" class="login-brand" alt />
    </div>
    <div class="h-100 d-flex justify-content-center align-items-center" v-else>
      <img
        v-if="!showWanasaLogo"
        src="@/assets/img/logos/logo-dark.png"
        height="auto"
        width="130"
        class="login-brand"
      />
      <img
        v-if="showWanasaLogo"
        src="@/assets/img/wanasaImage.png"
        height="auto"
        width="130"
        class="login-brand"
      />
    </div>
  </div>
</template>
<script>
  // import ScreenService from "@/services/ScreenService";
  import MasterService from "@/services/MasterService";
  import { BASEURL } from "@/config/index";
  import FnBitems from "@/components/Pages/foodAndBeverages.vue";
  import FinalTicket from "@/components/Pages/FinalTicket.vue";
  import { mapState, mapGetters, mapActions } from "vuex";
  import CovidBlockedSeatIcon from "@/components/Partials/CovidBlockedSeatIcon.vue";
  import { EventBus } from "@/main.js";
  import { CLIENT_NAME } from "@/helper/Role";

  export default {
    name: "PosSeatLayout",
    props: {
      showFullScreen: { type: Function },
    },
    components: {
      FnBitems,
      FinalTicket,
      CovidBlockedSeatIcon,
    },
    data() {
      return {
        currencyData: [],
        fullscreen: false,
        posBooking: false,
        posFnb: false,
        reverse: false,
        URL_BASE: BASEURL,
        selectedSeats: [],
        selectionType: "Normal", //Bulk,
        no_of_seats: 3,
        orgDetails: {},
        curr_code: "",
        FnBList: [],
        AdditionalChargeList: [],
        showCheckout: false,
        BookingDetails: {},
        showWanasaLogo: false,
      };
    },
    computed: {
      QfxClient() {
        return CLIENT_NAME.QFX == this.CLIENT_SETUP_NAME;
      },
      SelectedSeats() {
        return this.$store.getters.SELECTED_SEATS;
      },
      seatType() {
        return this.$store.getters.SEAT_LAYOUT_DATA;
      },
      scheduleShowId() {
        return this.$store.getters.SCHEDULE_SHOW_ID;
      },
      getScreenPosNames() {
        return this.$store.getters.getScreenPosNames;
      },
      currency() {
        return this.$store.getters.GET_CURRENCY;
      },
      // additonalChargeList() {
      //   return this.$store.getters.AdditionalTaxList;
      // }
      // // ...mapGetters(["AdditionalTaxList"])
    },
    watch: {
      FnBList(newVal, oldVal) {
        if (this.FnBList.length == 0) {
          this.fullscreen = false;
          this.posFnb = false;
        }
      },
    },
    sockets: {
      connect: function () {
        console.log("socket connected");
      },
      selectSeatLayout: function (data) {},
      setSeatLayout: function (data) {
        this.fullscreen = true;
        this.getCurrencyCode();
      },
      setDemo: function (data) {
        console.log("data :", data);
        this.fullscreen = false;
      },
      setOrgDetails: function (data) {
        console.log("data :", data);
        this.orgDetails = data;
      },
      FinalTicket: function (data) {
        this.showCheckout = true;
        this.fullscreen = false;
        this.posBooking = false;
        this.posFnb = false;
        console.log("FinalTicket Data", data);
        this.BookingDetails = data;
        this.curr_code = data.curr_code;
      },
      setPosSeatLayout: function (flag, currency_code) {
        console.log("flag>>>>>", flag, currency_code);
        this.fullscreen = flag[0];
        this.posBooking = flag[0];
        this.curr_code = this.fullscreen[1];
      },

      //Set FnB Show
      setPosFnB: function (data) {
        console.log("data :", data);
        this.fullscreen = data.flag;
        this.posFnb = data.flag;
        this.curr_code = data.curr_code;
        this.FnBList = data.fnbItems;
        this.AdditionalChargeList = data.charges;
      },

      setHomePage: function () {
        console.log("dsfsdfsd");
        this.fullscreen = false;
        this.posBooking = false;
        this.showCheckout = false;
        this.FnBList = [];
      },

      setSeatLayoutData: function (data) {
        console.log("data ::: dasdfs", data);
        if (data.hasOwnProperty("reverse")) {
          this.reverse = data.reverse;
        }
        window.localStorage.setItem("JWT_TOKEN", data.JWT_TOKEN);
        this.$store.dispatch("GET_SEAT_LAYOUT", {
          screen_id: data.screen_id,
          ss_id: data.ss_id,
          md_id: data.md_id,
        });
        this.fullscreen = true;
        this.posBooking = true;
      },
      setNumberofSeats: function (data) {
        console.log("data>>>>>>>><<<<<<<<<<< :", data);
        this.$store.dispatch("SelectNumberOfSeats", data.number);
      },
      seatSelectionFromBrij: async function (data) {
        console.log("data :::::", data);
        // await this.$store.dispatch("SET_SCHEDULE_SHOW_ID", data.scheduleShowId);
        // await this.$store.dispatch("MAKE_SEAT_EMPTY");
        // let payload = {
        //   seat: data.seat,
        //   rowIndex: data.rowIndex,
        //   seatIndex: data.seatIndex,
        //   originalIndex: data.originalIndex,
        //   backward: data.backward,
        //   e: data.e,
        //   type: data.type,
        // };
        await this.$store.dispatch("seatHandler", data);

        // await this.SocketHandlerSeatSelected(
        //   data.seat,
        //   data.rowIndex,
        //   data.seatIndex,
        //   data.originalIndex,
        //   data.backward,
        //   data.e,
        //   data.type
        // );
      },
    },
    beforeMount() {
      this.showWanasaLogo = window.location.origin.includes("wanasa");
    },
    mounted() {
      console.log("object :");
      this.getCurrencyCode();
      this.getSeatLayout();
      let self = this;

      if (CLIENT_NAME.CARNIVAL == this.CLIENT_SETUP_NAME) {
        EventBus.$on("ScrollIntoSelectedSeat", (data) => {
          console.log(data, "Comming Data ScrollinG");

          if (data) {
            setTimeout(() => {
              // const el = document.querySelector(`#${data}`);
              // console.log(el, "elllllll");
              // if (el) {
              //   el.scrollIntoView();
              // }
              const el = document.getElementById(String(data));
              console.log(el, "elllllll");
              if (el) {
                el.scrollIntoView();
              }

              // self.$refs["7179"].$el.scrollIntoView({ behavior: "smooth" });
            }, 1000);

            // self.$refs["7179"].$el.scrollIntoView({ behavior: "smooth" });
          }
        });
      }

      // this.$store.dispatch("NO_OF_SEATS", no_of_seats);
    },

    methods: {
      // POS OFFERS SAIF START
      ...mapActions(["fetchOffersList", "onSetSelectedOfferId"]),
      // POS OFFERS SAIF END
      isWheelChairOnlyRow(SeatTypeArray = []) {
        let statusCount = 0;

        SeatTypeArray.forEach((row) => {
          row.forEach((x) => {
            if (x.seat_reserve_type_id != 2 && x.seat_reserve_type_id != 3) {
              statusCount++;
            }
          });
        });

        if (statusCount > 0) {
          return false;
        } else {
          return true;
        }
      },
      getCurrencyCode: function () {
        let payload = {
          tableName: "ms_currencies",
          curr_is_active: "Y",
          curr_id:
            this.secureStore.get("BRGLOPCU") &&
            this.secureStore.get("BRGLOPCU").go_value,
        };
        MasterService.GetActiveList(payload)
          .then((response) => {
            const { data } = response;
            if (data && data.status && data.Records.length > 0) {
              this.currencyData = data.Records[0];
            } else {
              // Show Error Message:
              console.log("data.message :", data.message);
            }
          })
          .catch((error) => {
            // Catch Error:
            console.log("Catch Error on getActiveScreenByCinemaId :", error);
          });
      },
      showFullScreenFunc: function () {
        this.$socket.emit("pos_seatlayout_flag_change", !this.fullscreen);
      },

      getSeatLayout: function () {
        // let payload = {};
        // ScreenService.getSeatLayout(payload);
        console.log(this.$store.getters.SEAT_LAYOUT_DATA, "data of store");
      },

      SocketHandlerSeatSelected: async function (
        seat,
        rowIndex,
        seatIndex,
        originalIndex,
        backward,
        e,
        type
      ) {
        // await this.onSetSelectedOfferId({ name: "isSeatType", id: seat.sst_id });
        // await this.fetchOffersList({ seat_type_id: seat.sst_id });

        seat.seatIndex = seatIndex;
        seat.rowIndex = rowIndex;
        this.$store.dispatch("SEAT_SELECTION", {
          seat: seat,
          seatIndex: seatIndex,
          rowIndex: rowIndex,
          classIndex: type,
          scheduleShowId: this.scheduleShowId,
        });
      },

      // handlerSeatSelected: async function (
      //   seat,
      //   rowIndex,
      //   seatIndex,
      //   originalIndex,
      //   backward,
      //   e,
      //   type
      // ) {
      //   await this.onSetSelectedOfferId({ name: "isSeatType", id: seat.sst_id });
      //   await this.fetchOffersList({ seat_type_id: seat.sst_id });

      //   // console.log(
      //   //   "type :",
      //   //   seat,
      //   //   rowIndex,
      //   //   seatIndex,
      //   //   originalIndex,
      //   //   backward,
      //   //   e,
      //   //   type
      //   // );
      //   // console.log('e', e)
      //   // if(e){ e.preventDefault()}
      //   //this.events.splice(this.events.indexOf(event), 1);
      //   seat.seatIndex = seatIndex;
      //   seat.rowIndex = rowIndex;
      //   this.$store.dispatch("SEAT_SELECTION", {
      //     seat: seat,
      //     seatIndex: seatIndex,
      //     rowIndex: rowIndex,
      //     classIndex: type,
      //     scheduleShowId: this.scheduleShowId,
      //   });

      //   this.$socket.emit("on_select_seats", {
      //     seat,
      //     rowIndex,
      //     seatIndex,
      //     originalIndex,
      //     backward,
      //     e,
      //     type,
      //     scheduleShowId: this.scheduleShowId,
      //   });

      //   // seat.classIndex = classIndex;
      //   // if (type) {
      //   //   if (this.SeatType.length > 0 && this.SeatType !== type) {
      //   //     this.SeatType = "";
      //   //     this.selectedSeats.map(x => {
      //   //       this.seatLayout[x.rowIndex][x.seatIndex].SelectStatus = false;
      //   //       this.seatLayout[x.rowIndex][x.seatIndex].SelectStatusFlag = false;
      //   //     });
      //   //     this.selectedSeats = [];
      //   //     this.handlerSeatSelected(
      //   //       this.seatLayout[rowIndex][parseInt(seatIndex)],
      //   //       rowIndex,
      //   //       seatIndex,
      //   //       originalIndex,
      //   //       backward,
      //   //       e,
      //   //       type
      //   //     );
      //   //   }
      //   //   this.SeatType = type;
      //   // }
      //   if (this.selectionType == "Normal") {
      //   } else if (this.selectionType == "Bulk") {
      //     let firstTime = 0;
      //     if (originalIndex == null) {
      //       firstTime = 1;
      //       originalIndex = seatIndex;
      //     } else if (this.totalNumberOfSeats != this.selectedSeats.length) {
      //       this.seatLayout[rowIndex][seatIndex].SelectStatusFlag = true;
      //     }
      //     if (this.seatLayout[rowIndex][seatIndex].SelectStatusFlag == false) {
      //       if (this.totalNumberOfSeats != this.selectedSeats.length) {
      //         this.seatLayout[rowIndex][seatIndex].SelectStatusFlag = true;
      //       }
      //     }

      //     if (
      //       this.totalNumberOfSeats == this.selectedSeats.length &&
      //       firstTime == 1
      //     ) {
      //       //deselect all
      //       this.selectedSeats.map((x) => {
      //         this.seatLayout[x.rowIndex][x.seatIndex].SelectStatus = false;
      //         this.seatLayout[x.rowIndex][x.seatIndex].SelectStatusFlag = false;
      //       });
      //       this.selectedSeats = [];
      //     }
      //     if (this.seatLayout[rowIndex][seatIndex].SelectStatus == false) {
      //       //check if seat is selected, if yes, do nothing else
      //       //check how many selected
      //       if (
      //         parseInt(this.totalNumberOfSeats) - this.selectedSeats.length >
      //         0
      //       ) {
      //         this.seatLayout[rowIndex][seatIndex].SelectStatus = true;
      //         this.seatLayout[rowIndex][seatIndex].SelectStatusFlag = true;
      //         this.selectedSeats.push(seat);
      //         //check on right if available, if yes, select
      //         if (
      //           this.seatLayout[rowIndex][parseInt(seatIndex) + 1] &&
      //           backward != "backward" &&
      //           this.seatLayout[rowIndex][parseInt(seatIndex) + 1].Status == 1
      //         ) {
      //           //                        this.selectedSeats.push(seat);
      //           //                        this.seatData.SeatLayouts[classIndex].Seats[rowIndex][parseInt(seatIndex) + 1].SelectStatus = true;
      //           this.handlerSeatSelected(
      //             this.seatLayout[rowIndex][parseInt(seatIndex) + 1],
      //             rowIndex,
      //             seatIndex + 1,
      //             originalIndex
      //           );

      //           //check on left if available, if yes, select
      //         } else if (
      //           this.seatLayout[rowIndex][parseInt(originalIndex) - 1] &&
      //           this.seatLayout[rowIndex][parseInt(originalIndex) - 1].Status == 1
      //         ) {
      //           //                        this.selectedSeats.push(seat);
      //           //                        this.seatData.SeatLayouts[classIndex].Seats[rowIndex][parseInt(originalIndex) - 1].SelectStatus = true;
      //           this.handlerSeatSelected(
      //             this.seatLayout[rowIndex][parseInt(originalIndex) - 1],
      //             rowIndex,
      //             originalIndex - 1,
      //             originalIndex - 1,
      //             "backward"
      //           );
      //         }
      //       } else {
      //         if (firstTime == 1) {
      //           this.selectedSeats = [];
      //           this.handlerSeatSelected(
      //             this.seatLayout[rowIndex][parseInt(seatIndex)],
      //             rowIndex,
      //             seatIndex
      //           );
      //         }
      //       }
      //     }
      //   }

      //   //compare with total

      //   //if total > selected

      //   //check on right if available, if yes, select

      //   //check on left if available, if yes, select

      //   //if total == selected

      //   //if seat selected is selected, do nothing

      //   // if seat selected is not selected, remove all selected and start again

      //   //          let isInArray = 0;
      //   //          this.selectedSeats.map((x, index)=>{
      //   //              if(x.SeatName == seat.SeatName ){
      //   //                  this.$delete(this.selectedSeats, index);
      //   //                  isInArray = 1;
      //   //              }
      //   //          });
      //   //          if(isInArray == 0){
      //   //              this.selectedSeats.push(seat);
      //   //          }
      //   // this.totalAmount = 0.0;
      //   // this.selectedSeats.map((x, index) => {
      //   //   this.selectedSeatName.push(x.SeatName);
      //   //   this.totalAmount =
      //   //     parseFloat(this.totalAmount) + parseFloat(x.SeatPrice);
      //   // });
      // },
    },
  };
</script>

<style>
  .grey {
    background-color: lightgray;
  }
  .hideScreen {
    height: 0;
    margin-bottom: 0 !important;
    overflow: hidden;
  }
  .normal-seat {
    display: none !important;
  }
  .pos-seatlayout table td {
    min-width: 50px;
    max-width: 65px;
    min-height: 50px;
    padding-bottom: 2px;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
  .pos-seatlayout table td.pos-seatLayoutTitle {
    min-width: 50px;
    max-width: 65px;
    min-height: 50px;
    padding-bottom: 2px;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
  }
  .pos-seatlayout-rotate {
    transform: scale(1, -1);
  }
  .pos-seatlayout-rotate-text {
    transform: scale(1, -1);
  }
  .seatTypetext {
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    padding: 8px 0px 8px 0px;
    text-align: center;
    letter-spacing: 1px;
    text-decoration: underline;
    /* border-bottom: 1px solid #000; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
